import { createRouter, createWebHistory } from "vue-router";
import store from "../store";

const routes = [
  {
    path: "/:pathMatch(.*)*",
    name: "error404",
    meta: {
      auth: false,
    },
    component: () => import("../views/error/404.vue"),
  },
  {
    path: "/registro",
    name: "registro",
    meta: {
      auth: false,
    },
    component: () => import("../views/registro/Index.vue"),
  },
  {
    path: "/login",
    name: "login",
    meta: {
      auth: false,
    },
    component: () => import("../views/login/Index.vue"),
  },
  {
    path: "/recuperar-clave",
    name: "recuperarClave",
    meta: {
      auth: false,
    },
    component: () => import("../views/recuperarClave/Index.vue"),
  },
  {
    path: "/seleccionar-empresa",
    name: "seleccionarEmpresa",
    meta: {
      auth: true,
    },
    component: () => import("../views/seleccionEmpresa/Index.vue"),
  },
  {
    path: "/",
    redirect: "/inicio",
    // component: () => import("../views/Template.vue"),
    component: () => import("../layout/Template.vue"),
    meta: {
      auth: true,
    },
    children: [
      {
        path: "/inicio",
        name: "inicio",
        meta: {
          auth: true,
          group: 'inicio'
        },
        component: () => import("../views/inicio/Index.vue"),
      },

      {
        path: "/mi-perfil",
        name: "miPerfil",
        meta: {
          auth: true,
          group: 'miPerfil'
        },
        component: () => import("../views/miPerfil/Index.vue"),
      },

      {
        path: "/configuracion",
        name: "configuracion",
        meta: {
          auth: true,
          group: 'configuracion'
        },
        component: () => import("../views/configuracion/Index.vue"),
      },

      {
        path: "/empresas",
        name: "empresas",
        redirect: "/empresas/mAPzadmjRyJg/editar",
        meta: {
          auth: true,
          group: 'empresas'
        },
        component: () => import("../views/empresas/Index.vue"),
      },
      {
        path: "/empresas/crear",
        name: "empresasCrear",
        meta: {
          auth: true,
          group: 'empresas'
        },
        component: () => import("../views/empresas/Crear.vue"),
      },
      {
        path: "/empresas/:idempresa/editar",
        name: "empresasEditar",
        meta: {
          auth: true,
          group: 'empresas'
        },
        component: () => import("../views/empresas/Editar.vue"),
      },

      {
        path: "/usuarios",
        name: "usuarios",
        meta: {
          auth: true,
          group: 'usuarios'
        },
        component: () => import("../views/usuarios/Index.vue"),
      },
      {
        path: "/usuarios/crear",
        name: "usuariosCrear",
        meta: {
          auth: true,
          group: 'usuarios'
        },
        component: () => import("../views/usuarios/Crear.vue"),
      },
      {
        path: "/usuarios/:idusuario/editar",
        name: "usuariosEditar",
        meta: {
          auth: true,
          group: 'usuarios'
        },
        component: () => import("../views/usuarios/Editar.vue"),
      },

      {
        path: "/roles",
        name: "roles",
        meta: {
          auth: true,
          group: 'roles',
        },
        component: () => import("../views/roles/Index.vue"),
      },
      {
        path: "/roles/crear",
        name: "rolesCrear",
        meta: {
          auth: true,
          group: 'roles',
        },
        component: () => import("../views/roles/Crear.vue"),
      },
      {
        path: "/roles/:idrol/editar",
        name: "rolesEditar",
        meta: {
          auth: true,
          group: 'roles',
        },
        component: () => import("../views/roles/Editar.vue"),
      },

      {
        path: "/categorias",
        name: "categorias",
        meta: {
          auth: true,
          group: 'categorias'
        },
        component: () => import("../views/categorias/Index.vue"),
      },
      {
        path: "/categorias/crear",
        name: "categoriasCrear",
        meta: {
          auth: true,
          group: 'categorias'
        },
        component: () => import("../views/categorias/Crear.vue"),
      },
      {
        path: "/categorias/:idcategoria/editar",
        name: "categoriasEditar",
        meta: {
          auth: true,
          group: 'categorias'
        },
        component: () => import("../views/categorias/Editar.vue"),
      },


      {
        path: "/lineas",
        name: "lineas",
        meta: {
          auth: true,
          group: 'lineas'
        },
        component: () => import("../views/linea/Index.vue"),
      },
      {
        path: "/linea/crear",
        name: "lineaCrear",
        meta: {
          auth: true,
          group: 'lineas'
        },
        component: () => import("../views/linea/Crear.vue"),
      },
      {
        path: "/linea/:idlinea/editar",
        name: "lineaEditar",
        meta: {
          auth: true,
          group: 'lineas'
        },
        component: () => import("../views/linea/Editar.vue"),
      },


      {
        path: "/impuestos",
        name: "impuestos",
        meta: {
          auth: true,
          group: 'impuestos'
        },
        component: () => import("../views/impuesto/Index.vue"),
      },
      {
        path: "/impuesto/crear",
        name: "impuestoCrear",
        meta: {
          auth: true,
          group: 'impuestos'
        },
        component: () => import("../views/impuesto/Crear.vue"),
      },
      {
        path: "/impuesto/:idimpuesto/editar",
        name: "impuestoEditar",
        meta: {
          auth: true,
          group: 'impuestos'
        },
        component: () => import("../views/impuesto/Editar.vue"),
      },



      {
        path: "/detracciones",
        name: "detracciones",
        meta: {
          auth: true,
          group: 'detracciones'
        },
        component: () => import("../views/detraccion/Index.vue"),
      },
      {
        path: "/detraccion/crear",
        name: "detraccionCrear",
        meta: {
          auth: true,
          group: 'detracciones'
        },
        component: () => import("../views/detraccion/Crear.vue"),
      },
      {
        path: "/detraccion/:iddetraccion/editar",
        name: "detraccionEditar",
        meta: {
          auth: true,
          group: 'detracciones'
        },
        component: () => import("../views/detraccion/Editar.vue"),
      },



      {
        path: "/marcas",
        name: "marcas",
        meta: {
          auth: true,
          group: 'marcas'
        },
        component: () => import("../views/marcas/Index.vue"),
      },
      {
        path: "/marcas/crear",
        name: "marcasCrear",
        meta: {
          auth: true,
          group: 'marcas'
        },
        component: () => import("../views/marcas/Crear.vue"),
      },
      {
        path: "/marcas/:idmarca/editar",
        name: "marcasEditar",
        meta: {
          auth: true,
          group: 'marcas'
        },
        component: () => import("../views/marcas/Editar.vue"),
      },

      {
        path: "/clientes",
        name: "clientes",
        meta: {
          auth: true,
          group: 'clientes'
        },
        component: () => import("../views/clientes/Index.vue"),
      },
      {
        path: "/clientes/crear",
        name: "clientesCrear",
        meta: {
          auth: true,
          group: 'clientes'
        },
        component: () => import("../views/clientes/Crear.vue"),
      },
      {
        path: "/clientes/:idcliente/editar",
        name: "clientesEditar",
        meta: {
          auth: true,
          group: 'clientes'
        },
        component: () => import("../views/clientes/Editar.vue"),
      },

      {
        path: "/choferes",
        name: "chofer.index",
        meta: {
          auth: true,
          group: 'chofer'
        },
        component: () => import("../views/chofer/Index.vue"),
      },
      {
        path: "/choferes/crear",
        name: "chofer.create",
        meta: {
          auth: true,
          group: 'chofer'
        },
        component: () => import("../views/chofer/Crear.vue"),
      },
      {
        path: "/choferes/:idchofer/editar",
        name: "chofer.edit",
        meta: {
          auth: true,
          group: 'chofer'
        },
        component: () => import("../views/chofer/Editar.vue"),
      },


      {
        path: "/vehiculos",
        name: "vehiculo.index",
        meta: {
          auth: true,
          group: 'vehiculo'
        },
        component: () => import("../views/vehiculo/Index.vue"),
      },
      {
        path: "/vehiculos/crear",
        name: "vehiculo.create",
        meta: {
          auth: true,
          group: 'vehiculo'
        },
        component: () => import("../views/vehiculo/Crear.vue"),
      },
      {
        path: "/vehiculos/:idvehiculo/editar",
        name: "vehiculo.edit",
        meta: {
          auth: true,
          group: 'vehiculo'
        },
        component: () => import("../views/vehiculo/Editar.vue"),
      },



      {
        path: "/productos",
        name: "productos",
        meta: {
          auth: true,
          group: 'productos'
        },
        component: () => import("../views/productos/Index.vue"),
      },
      {
        path: "/productos/crear",
        name: "productosCrear",
        meta: {
          auth: true,
          group: 'productos'
        },
        component: () => import("../views/productos/Crear.vue"),
      },
      {
        path: "/productos/:idproducto/editar",
        name: "productosEditar",
        meta: {
          auth: true,
          group: 'productos'
        },
        component: () => import("../views/productos/Editar.vue"),
      },

    


      {
        path: "/auditorias",
        name: "auditorias",
        meta: {
          auth: true,
          group: 'auditorias'
        },
        component: () => import("../views/auditoria/Index.vue"),
      },

      {
        path: "/orden-pedido",
        name: "ordenPedido.index",
        meta: {
          auth: true,
          group: 'ordenPedido'
        },
        component: () => import("../views/ordenPedido/Index.vue"),
      },
      {
        path: "/orden-pedido/crear",
        name: "ordenPedido.create",
        meta: {
          auth: true,
          group: 'ordenPedido'
        },
        component: () => import("../views/ordenPedido/Crear.vue"),
      },
      {
        path: "/orden-pedido/editar/:id",
        name: "ordenPedido.edit",
        meta: {
          auth: true,
          group: 'ordenPedido'
        },
        component: () => import("../views/ordenPedido/Editar.vue"),
      },
      

      {
        path: "/orden-almacen",
        name: "ordenDespacho.index",
        meta: {
          auth: true,
          group: 'ordenDespacho'
        },
        component: () => import("../views/ordenDespacho/Index.vue"),
      },
      {
        path: "/orden-almacen/crear/:orden_pedido_id?",
        name: "ordenDespacho.create",
        meta: {
          auth: true,
          group: 'ordenDespacho'
        },
        component: () => import("../views/ordenDespacho/Crear.vue"),
      },
      {
        path: "/orden-almacen/editar/:idorden_despacho",
        name: "ordenDespacho.edit",
        meta: {
          auth: true,
          group: 'ordenDespacho'
        },
        component: () => import("../views/ordenDespacho/Editar.vue"),
      },


      {
        path: "/guias",
        name: "guia.index",
        meta: {
          auth: true,
          group: 'guia'
        },
        component: () => import("../views/guia/Index.vue"),
      },
      {
        path: "/guias/crear/:orden_pedido_id?",
        name: "guia.create",
        meta: {
          auth: true,
          group: 'guia'
        },
        component: () => import("../views/guia/Crear.vue"),
      },
      {
        path: "/guias/editar/:id",
        name: "guia.edit",
        meta: {
          auth: true,
          group: 'guia'
        },
        component: () => import("../views/guia/Editar.vue"),
      },

      {
        path: "/ventas",
        name: "venta.index",
        meta: {
          auth: true,
          group: 'venta'
        },
        component: () => import("../views/venta/Index.vue"),
      },
      {
        path: "/ventas/crear",
        name: "venta.create",
        meta: {
          auth: true,
          group: 'venta'
        },
        component: () => import("../views/venta/Crear.vue"),
      },
      {
        path: "/ventas/editar/:id",
        name: "venta.edit",
        meta: {
          auth: true,
          group: 'venta'
        },
        component: () => import("../views/venta/Editar.vue"),
      },




      {
        path: "/notas-de-credito",
        name: "notaCredito.index",
        meta: {
          auth: true,
          group: 'notaCredito'
        },
        component: () => import("../views/notaCredito/Index.vue"),
      },
      {
        path: "/notas-de-credito/crear/:venta_id?",
        name: "notaCredito.create",
        meta: {
          auth: true,
          group: 'notaCredito'
        },
        component: () => import("../views/notaCredito/Crear.vue"),
      },
      {
        path: "/notas-de-credito/editar/:id",
        name: "notaCredito.edit",
        meta: {
          auth: true,
          group: 'notaCredito'
        },
        component: () => import("../views/notaCredito/Editar.vue"),
      },

      {
        path: "/notas-de-debito",
        name: "notaDebito.index",
        meta: {
          auth: true,
          group: 'notaDebito'
        },
        component: () => import("../views/notaDebito/Index.vue"),
      },
      {
        path: "/notas-de-debito/crear/:venta_id?",
        name: "notaDebito.create",
        meta: {
          auth: true,
          group: 'notaDebito'
        },
        component: () => import("../views/notaDebito/Crear.vue"),
      },
      {
        path: "/notas-de-debito/editar/:id",
        name: "notaDebito.edit",
        meta: {
          auth: true,
          group: 'notaDebito'
        },
        component: () => import("../views/notaDebito/Editar.vue"),
      },




      {
        path: "/cuota/fecha-limite",
        name: "cuota.fechaLimite",
        meta: {
          auth: true,
          group: 'cuota'
        },
        component: () => import("../views/cuota/FechaLimite.vue"),
      },

      {
        path: "/letra-cambio",
        name: "letraCambio.index",
        meta: {
          auth: true,
          group: 'letraCambio'
        },
        component: () => import("../views/letraCambio/Index.vue"),
      },
      {
        path: "/letra-cambio/crear",
        name: "letraCambio.create",
        meta: {
          auth: true,
          group: 'letraCambio'
        },
        component: () => import("../views/letraCambio/Crear.vue"),
      },
      {
        path: "/letra-cambio/editar/:id",
        name: "letraCambio.edit",
        meta: {
          auth: true,
          group: 'letraCambio'
        },
        component: () => import("../views/letraCambio/Editar.vue"),
      },




      {
        path: "/orden-produccion",
        name: "ordenProduccion.index",
        meta: {
          auth: true,
          group: 'ordenProduccion'
        },
        component: () => import("../views/ordenProduccion/Index.vue"),
      },
      {
        path: "/orden-produccion/crear",
        name: "ordenProduccion.create",
        meta: {
          auth: true,
          group: 'ordenProduccion'
        },
        component: () => import("../views/ordenProduccion/Crear.vue"),
      },
      {
        path: "/orden-produccion/editar/:id",
        name: "ordenProduccion.edit",
        meta: {
          auth: true,
          group: 'ordenProduccion'
        },
        component: () => import("../views/ordenProduccion/Editar.vue"),
      },


  
      {
          path: "/almacen",
          name: "almacen.index",
          meta: {
            auth: true,
            group: 'almacen'
          },
          component: () => import("../views/almacen/Index.vue"),
      },
      {
        path: "/almacen/crear",
        name: "almacen.create",
        meta: {
            auth: true,
            group: 'almacen'
        },
        component: () => import("../views/almacen/Crear.vue"),
      },
      {
        path: "/almacen/editar/:idalmacen",
        name: "almacen.edit",
        meta: {
            auth: true,
            group: 'almacen'
        },
        component: () => import("../views/almacen/Editar.vue"),
      },
  
  
  
      {
          path: "/tipo",
          name: "tipo.index",
          meta: {
            auth: true,
            group: 'tipo'
          },
          component: () => import("../views/tipo/Index.vue"),
      },
      {
        path: "/tipo/crear",
        name: "tipo.create",
        meta: {
            auth: true,
            group: 'tipo'
        },
        component: () => import("../views/tipo/Crear.vue"),
      },
      {
        path: "/tipo/editar/:idtipo",
        name: "tipo.edit",
        meta: {
            auth: true,
            group: 'tipo'
        },
        component: () => import("../views/tipo/Editar.vue"),
      },
  
  
  
      {
          path: "/tipo-producto",
          name: "tipoProducto.index",
          meta: {
            auth: true,
            group: 'tipoProducto'
          },
          component: () => import("../views/tipoProducto/Index.vue"),
      },
      {
        path: "/tipo-producto/crear",
        name: "tipoProducto.create",
        meta: {
            auth: true,
            group: 'tipoProducto'
        },
        component: () => import("../views/tipoProducto/Crear.vue"),
      },
      {
        path: "/tipo-producto/editar/:idtipo_producto",
        name: "tipoProducto.edit",
        meta: {
            auth: true,
            group: 'tipoProducto'
        },
        component: () => import("../views/tipoProducto/Editar.vue"),
      },
  
      
  
      {
          path: "/zona",
          name: "zona.index",
          meta: {
            auth: true,
            group: 'zona'
          },
          component: () => import("../views/zona/Index.vue"),
      },
      {
        path: "/zona/crear",
        name: "zona.create",
        meta: {
            auth: true,
            group: 'zona'
        },
        component: () => import("../views/zona/Crear.vue"),
      },
      {
        path: "/zona/editar/:idzona",
        name: "zona.edit",
        meta: {
            auth: true,
            group: 'zona'
        },
        component: () => import("../views/zona/Editar.vue"),
      },
  
  
  
  
  
      {
          path: "/unidad-medida",
          name: "unidadMedida.index",
          meta: {
            auth: true,
            group: 'unidadMedida'
          },
          component: () => import("../views/unidadMedida/Index.vue"),
      },
      {
        path: "/unidad-medida/crear",
        name: "unidadMedida.create",
        meta: {
            auth: true,
            group: 'unidadMedida'
        },
        component: () => import("../views/unidadMedida/Crear.vue"),
      },
      {
        path: "/unidad-medida/editar/:idunidad_medida",
        name: "unidadMedida.edit",
        meta: {
            auth: true,
            group: 'unidadMedida'
        },
        component: () => import("../views/unidadMedida/Editar.vue"),
      },
  
  
  
  
      {
          path: "/cuenta-compra-venta",
          name: "cuentaCompraVenta.index",
          meta: {
            auth: true,
            group: 'cuentaCompraVenta'
          },
          component: () => import("../views/cuentaCompraVenta/Index.vue"),
      },
      {
        path: "/cuenta-compra-venta/crear",
        name: "cuentaCompraVenta.create",
        meta: {
            auth: true,
            group: 'cuentaCompraVenta'
        },
        component: () => import("../views/cuentaCompraVenta/Crear.vue"),
      },
      {
        path: "/cuenta-compra-venta/editar/:idcuenta_compra_venta",
        name: "cuentaCompraVenta.edit",
        meta: {
            auth: true,
            group: 'cuentaCompraVenta'
        },
        component: () => import("../views/cuentaCompraVenta/Editar.vue"),
      },
  
  
  
      {
          path: "/entidad-financiera",
          name: "entidadFinanciera.index",
          meta: {
            auth: true,
            group: 'entidadFinanciera'
          },
          component: () => import("../views/entidadFinanciera/Index.vue"),
      },
      {
        path: "/entidad-financiera/crear",
        name: "entidadFinanciera.create",
        meta: {
            auth: true,
            group: 'entidadFinanciera'
        },
        component: () => import("../views/entidadFinanciera/Crear.vue"),
      },
      {
        path: "/entidad-financiera/editar/:identidad_financiera",
        name: "entidadFinanciera.edit",
        meta: {
            auth: true,
            group: 'entidadFinanciera'
        },
        component: () => import("../views/entidadFinanciera/Editar.vue"),
      },
  
  
  
  
  
  
      {
          path: "/codigo-comprobante",
          name: "codigoComprobante.index",
          meta: {
            auth: true,
            group: 'codigoComprobante'
          },
          component: () => import("../views/codigoComprobante/Index.vue"),
      },
      {
        path: "/codigo-comprobante/crear",
        name: "codigoComprobante.create",
        meta: {
            auth: true,
            group: 'codigoComprobante'
        },
        component: () => import("../views/codigoComprobante/Crear.vue"),
      },
      {
        path: "/codigo-comprobante/editar/:idcodigo_comprobante",
        name: "codigoComprobante.edit",
        meta: {
            auth: true,
            group: 'codigoComprobante'
        },
        component: () => import("../views/codigoComprobante/Editar.vue"),
      },




    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  // process.env.NODE_ENV === "production" ? "/cemedic/" : "/"
  /*history: createWebHistory(),*/
  routes,
});


const cerrarModales = () => {
    const modalesHtml = document.getElementsByClassName("modal");
    Array.from(modalesHtml).forEach((item) => {
        if (item) {
            $(item).modal('hide')
        }
    });
};

//protegiendo rutas
router.beforeEach((to, from, next) => {
  cerrarModales()
  let token = store.state.login.token;
  let empresa = store.state.empresa.empresa;

  if (to.meta.auth) {
    if (token != null && token != "" && token != undefined) {
      if (Object.keys(empresa).length == 0 && to.name != "seleccionarEmpresa") {
        next({ name: "seleccionarEmpresa" });
      } else {
        next();
      }
    } else {
      next({ name: "login" });
    }
  } else {
    if (
      token != null &&
      token != "" &&
      token != undefined &&
      (to.path == "/" ||
        to.name == "registro" ||
        to.name == "login" ||
        to.name == "recuperarClave")
    ) {
      next({ name: "inicio" });
    } else {
      next();
    }
  }
});

export default router;
