<script setup>
    import vSelect from 'vue-select';
    import "vue-select/dist/vue-select.css";

    vSelect.props.placeholder.default = '[---Seleccione---]';
    vSelect.props.clearable.default = false;


</script>

<template>
    <vSelect v-bind="$attrs" >
        <template #no-options> No hay opciones disponibles </template>
        
        <!-- Renderizar el slot `option` -->
        <template v-if="$slots.option" #option="option">
            <slot name="option" v-bind="option ?? {}" />
        </template>
    
        <!-- Renderizar el slot `selected-option` -->
        <template v-if="$slots['selected-option']" #selected-option="selectedOption">
            <slot name="selected-option" v-bind="selectedOption ?? {}" />
        </template>
    
        <!-- Renderizar cualquier otro slot adicional pasado -->
        <slot />
        
    </vSelect>
</template>

<style scoped>
    
</style>